import logo from "../logo-lavirginia.svg";
import logo2 from "./logo-fractal.png";
import logo3 from "./logo-loger.png";
import logo4 from "../cognitive.png"
import styled from '@emotion/styled';
import Select from 'react-select';
import axios from "axios";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import "../App.css";
import { ENDPOINT_NODE } from "../backend";
// import Dropdown from "./componentes/dropdown/dropdown.js";
import DragArea from "./dragArea/dragArea";
// import DragArea2 from "./componentes/dragArea/dragArea2";
import { useEffect, useState } from "react";
import DragArea6 from "./dragArea/dragArea6";
import atthacment from '../assets/images/atthacment-white.png';

const FormularioV2_2 = () => {
  const history = useHistory();
  const [dataFurniture, setDataFurniture] = useState([])
  const [busqueda, setBusqueda] = useState('')
  const [tabladFiltrado, setTablaFiltrado] = useState(["a", "b", "c"])
  const [valores, setValores] = useState(["a", "b", "c"])
  const [valoresaux, setValoresAux] = useState([1, 2, 3])
  const [selectedOption, setSelectedOption] = useState(null);
  const [repositorSeleccionado, setRepositorSeleccionado] = useState(null);
  const [puntoVentaSeleccionado, setPuntoVentaSeleccionado] = useState(null);
  const [supervisorSeleccionado, setSupervisorSeleccionado] = useState(null);
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState(null);
  const [direccionSeleccionada, setDireccionSeleccionada] = useState(null);
  const [valoresIP, setValoresIP] = useState(null)
  const [reposDisponibles, setReposDisponibles] = useState([]); // Initialize as empty array
  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    // Actualiza los repositorios disponibles cuando cambia el punto de venta
    // filtrarRepositores(selectedOption);
  };

  useEffect(() => {
    const fetchData = async () => {
  //     setIsLoading(true); // Muestra un indicador de carga mientras se realiza la solicitud

      try {
  //       const tokenData = localStorage.getItem('token'); // Obtén el token desde el localStorage
  //       const token = tokenData ? JSON.parse(tokenData).token : null; // Parsear el token JSON

  //       if (!token) {
  //         throw new Error('Token no disponible en localStorage'); // Manejar el caso en el que no haya token
  //       }

  //       // Realiza la solicitud a la API con el token en los headers
        const response = await fetch(`${ENDPOINT_NODE}/furniture`, {
          // headers: {
          //   'Authorization': 'Bearer ' + token
          // }
        });

  //       // Si la respuesta no es correcta, lanza un error
  //       if (!response.ok) {
  //         if (response.status === 401) { 
  //           // Token inválido o expirado
  //           console.error("Error 401: No autorizado");
  //           // Redirige al inicio de sesión, limpia el token, etc.
  //           localStorage.removeItem('token');
  //           window.location.href = '/login'; // Ajusta la ruta si es necesario 
  //         } else {
  //           // Otros errores
  //           throw new Error(`Error ${response.status}: ${response.statusText}`);
  //         }
  //       }

  //       // Obtén los datos JSON de la respuesta
        const jsonData = await response.json();
        // console.log('Datos JSON recibidos:', jsonData); // Imprime los datos recibidos

  //       // Actualiza el estado de dataProyecto con los datos recibidos
        setDataFurniture(jsonData);

  //       // Guarda los datos en el localStorage si es necesario
  //       localStorage.setItem('data-segmento', JSON.stringify(jsonData));

      } catch (error) {
        console.error('Error durante la solicitud:', error); // Manejo de errores
  //       setError(error); // Guarda el error en el estado para mostrarlo en la interfaz
      } finally {
  //       setIsLoading(false); // Detén el indicador de carga
      }
    };
    if(dataFurniture.length == 0) {
    fetchData(); // Llama a la función fetchData cuando el componente se monte o cambie el id
  }
  //
  }, [dataFurniture]);


  // Crea arrays de opciones para cada filtro (sin filtrar inicialmente)
  const allOpcionesSucursal = [...new Set(dataFurniture.map(item => item.nombre_sucursal))].map(sucursal => ({ value: sucursal, label: sucursal }));
  const allOpcionesSupervisor = [...new Set(dataFurniture.map(item => item.nombre_supervisor))].map(supervisor => ({ value: supervisor, label: supervisor }));
  const allOpcionesRepositor = [...new Set(dataFurniture.map(item => item.nombre_repositor))].map(repositor => ({ value: repositor, label: repositor }));
  const allOpcionesPuntosVenta = dataFurniture.map(item => ({
    value: item.codigo_cliente_sap,
    label: item.nombre_cliente,
    repositor: item.nombre_repositor,
    sucursal: item.nombre_sucursal,
    supervisor: item.nombre_supervisor
  }));
  const allOpcionesDirecciones = dataFurniture.map(item => ({
    value: item.direccion,
    label: item.direccion,
    repositor: item.nombre_repositor,
    sucursal: item.nombre_sucursal,
    supervisor: item.nombre_supervisor
  }));  
  

   // Funcion para filtrar el array de opciones puntos de venta basado en los filtros seleccionados.
   const getOpcionesPuntosVentaFiltrados = () => {
    let filtrados = allOpcionesPuntosVenta;
    if (sucursalSeleccionada) filtrados = filtrados.filter(item => item.sucursal === sucursalSeleccionada.value);
    if (supervisorSeleccionado) filtrados = filtrados.filter(item => item.supervisor === supervisorSeleccionado.value);
    if (repositorSeleccionado) filtrados = filtrados.filter(item => item.repositor === repositorSeleccionado.value);
    // if (direccionSeleccionada) filtrados = filtrados.filter(item => item.direccion === direccionSeleccionada.value);
    return filtrados;
  };

  // Funcion para filtrar el array de opciones repositorios basado en los filtros seleccionados.
  const getOpcionesRepositorFiltrados = () => {
    let filtrados = allOpcionesRepositor;
    const puntosVentaFiltrados = getOpcionesPuntosVentaFiltrados();
    if (puntosVentaFiltrados.length > 0) {
      filtrados = [...new Set(puntosVentaFiltrados.map(item => item.repositor))].map(repositor => ({ value: repositor, label: repositor }));
    }
    return filtrados;
  };

  const getOpcionesSupervisorFiltrados = () => {
    if (!sucursalSeleccionada) {
      // Si no hay sucursal seleccionada, devuelve todos los supervisores
      const supervisores = [...new Set(dataFurniture.map(item => item.nombre_supervisor))];
      return supervisores.map(supervisor => ({ value: supervisor, label: supervisor }));
    } else {
      // Si hay una sucursal seleccionada, filtra los supervisores
      const supervisores = [...new Set(dataFurniture.filter(item => item.nombre_sucursal === sucursalSeleccionada.value).map(item => item.nombre_supervisor))];
      return supervisores.map(supervisor => ({ value: supervisor, label: supervisor }));
    }
  };

  const getOpcionesDireccionesFiltrados = () => {
    let filtrados = allOpcionesDirecciones;
    if (sucursalSeleccionada) filtrados = filtrados.filter(item => item.sucursal === sucursalSeleccionada.value);
    if (supervisorSeleccionado) filtrados = filtrados.filter(item => item.supervisor === supervisorSeleccionado.value);
    if (repositorSeleccionado) filtrados = filtrados.filter(item => item.repositor === repositorSeleccionado.value);
    // if (direccionSeleccionada) filtrados = filtrados.filter(item => item.direccion === direccionSeleccionada.value);
    return filtrados;
  };


  const manejarCambioSucursal = (sucursalSeleccionada) => {
    setSucursalSeleccionada(sucursalSeleccionada);
    setSupervisorSeleccionado(null);
    setRepositorSeleccionado(null);
    setPuntoVentaSeleccionado(null);
  };

  const manejarCambioSupervisor = (supervisorSeleccionado) => {
    setSupervisorSeleccionado(supervisorSeleccionado);
    setRepositorSeleccionado(null);
    setPuntoVentaSeleccionado(null);
    if (supervisorSeleccionado) {
      const item = dataFurniture.find(item => item.nombre_supervisor === supervisorSeleccionado.value);
      if(item) {
          setSucursalSeleccionada({ value: item.nombre_sucursal, label: item.nombre_sucursal });
      }
    }
  };

  const manejarCambioRepositor = (repositorSeleccionado) => {
    setRepositorSeleccionado(repositorSeleccionado);
    setPuntoVentaSeleccionado(null);
    if (repositorSeleccionado) {
      const item = dataFurniture.find(item => item.nombre_repositor === repositorSeleccionado.value);
      if(item) {
          setSucursalSeleccionada({ value: item.nombre_sucursal, label: item.nombre_sucursal });
          setSupervisorSeleccionado({ value: item.nombre_supervisor, label: item.nombre_supervisor });
      }
  }
  };

  const manejarCambioPuntoVenta = (puntoVentaSeleccionado) => {
    setPuntoVentaSeleccionado(puntoVentaSeleccionado);
    if (puntoVentaSeleccionado) {
        const item = dataFurniture.find(item => item.codigo_cliente_sap === puntoVentaSeleccionado.value);
        if(item) {
            setSucursalSeleccionada({ value: item.nombre_sucursal, label: item.nombre_sucursal });
            setSupervisorSeleccionado({ value: item.nombre_supervisor, label: item.nombre_supervisor });
            setRepositorSeleccionado({ value: item.nombre_repositor, label: item.nombre_repositor });
            setDireccionSeleccionada({ value: item.direccion, label: item.direccion });
          }
    }
  };

  const manejarCambioDirecciones = (direccionSeleccionada) => {
    setDireccionSeleccionada(direccionSeleccionada);
    if (direccionSeleccionada) {
        const item = dataFurniture.find(item => item.direccion === direccionSeleccionada.value);
        if(item) {
            setSucursalSeleccionada({ value: item.nombre_sucursal, label: item.nombre_sucursal });
            setSupervisorSeleccionado({ value: item.nombre_supervisor, label: item.nombre_supervisor });
            setRepositorSeleccionado({ value: item.nombre_repositor, label: item.nombre_repositor });
            setPuntoVentaSeleccionado({ value: item.codigo_cliente_sap, label: item.nombre_cliente });
          }
    }
  };

  ////////////////////////////////////////////////////////////////////////

  const filtrarArray = (array) => {
    var ayuda2 = [];
    var items2 = array
    for (let i = 0; i < items2.length; i++) {
      ayuda2.push(i)
    }
    setValores(items2)
    setValoresAux(ayuda2)

    //   setCurrentItems(ayuda2.slice(itemOffset, itemOffset + itemsPerPage));
    //   setPageCount(Math.ceil(items2.length / itemsPerPage));
  }
  const handleChange = (e) => {
    setBusqueda(e.target.value)
    filtrar(e.target.value)
  }

  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = tabladFiltrado.filter((elemento) => {
      if (
        (elemento.image != null && elemento.image.includes(terminoBusqueda.toLowerCase()))
        ||
        (elemento.job_id != null && elemento.job_id.includes(terminoBusqueda.toLowerCase()))
        ||
        (elemento.created_at != null && elemento.created_at.toString().includes(terminoBusqueda.toLowerCase()))
      ) {
        return elemento;
      }
    });


    filtrarArray(resultadosBusqueda)
  }
  const getIp = async () => {

    try {
      // const response = await axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=bfa2898e16254f0baa4d590f25a4268f');
      // console.log(response);
      const response = await fetch('https://ipgeolocation.abstractapi.com/v1/?api_key=bfa2898e16254f0baa4d590f25a4268f');
      const data = await response.json();

      setValoresIP({
        ip_user: data.ip_address,
        user_country: data.city + ", " + data.country,
        conection_type: data.connection.connection_type,
        latitude: data.latitude,
        longitude: data.longitude,
        current_time: data.timezone.current_time,
      })
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    getIp();
  }, []);

  return (
    <div className="App container fondo">
      <br></br>
      <div className="row ">

        <div className="col-4 text-center">

        </div>
        <div className="col-4 text-center">

        </div>
        {/* <div className="col-4 text-center">
                <a href="/">Formulario actual</a>
            </div> */}
      </div>
      {/* <Formulario></Formulario> */}
      {/* <DragArea2 handleImageUpload={handleImageUpload} /> */}
      <header className="text-center ">
        <div className="container">
          <img src={logo} className="App-logo img-fluid mt-5" alt="logo" />
        </div>
        <div className="fixed-button">
        <button onClick={() => history.push('/formulariov4')} className="my-3 btn btn-warning text-white custom-btn">Foto de Entrenamiento<img className="padding-left-img" src={atthacment} width={25} alt="photo upload" /> </button>
        </div>
        {/* <Dropdown dropdownTitle="primer iterado" items={items} /> */}

        <br></br>
        <br></br>
        <div className="container">
        <div className="row mt-2">
          <div className="col-7 text-center">
          <Select
            options={allOpcionesSucursal}
            isSearchable
            value={sucursalSeleccionada}
            onChange={manejarCambioSucursal}
            placeholder="Seleccionar Sucursal"
          />
          </div>
          <div className="col-5 text-left-important my-auto">
            <h5 className="h5 text-white letra-standar">Sucursal</h5>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-7 text-center">
          <Select
            options={getOpcionesSupervisorFiltrados()}
            isSearchable
            value={supervisorSeleccionado}
            onChange={manejarCambioSupervisor}
            placeholder="Seleccionar Supervisor"
          />
          </div>
          <div className="col-5 text-left-important my-auto">
            <h5 className="h5 text-white letra-standar">Supervisor</h5>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-7 text-center">
          <Select
            options={getOpcionesRepositorFiltrados()}
            isSearchable
            value={repositorSeleccionado}
            onChange={manejarCambioRepositor}
            placeholder="Seleccionar Repositor"
          />
          </div>
          <div className="col-5 text-left-important my-auto">
            <h5 className="h5 text-white letra-standar">Repositor</h5>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-7 text-center">
            {/* <Select
              options={repositorSeleccionado ? opcionesPuntosVenta.filter((opcion) => opcion.nombre_repositor === repositorSeleccionado.value) : opcionesPuntosVenta} //Renderizado condicional de opciones
              isSearchable
              onChange={manejarCambioPuntoVenta}
              placeholder="Seleccionar Punto de Venta"
            /> */}
             <Select
            options={getOpcionesPuntosVentaFiltrados()}
            isSearchable
            value={puntoVentaSeleccionado}
            onChange={manejarCambioPuntoVenta}
            placeholder="Seleccionar Punto de Venta"
          />
          </div>
          <div className="col-5 text-left-important my-auto">
            <h5 className="h5 text-white letra-standar">Punto de Venta</h5>
          </div>
        </div>

          <div className="row mt-2">
            <div className="col-7 text-center">
              <Select
                options={getOpcionesDireccionesFiltrados()}
                isSearchable
                value={direccionSeleccionada}
                onChange={manejarCambioDirecciones}
                placeholder="Seleccionar Dirección"
              />
            </div>
            <div className="col-5 text-left-important my-auto">
              <h5 className="h5 text-white letra-standar">Dirección</h5>
            </div>
          </div>

          {/* <div className="row mt-2 ">
            <div className="col-7 text-center">
              <Select options={options} isSearchable onChange={handleOptionChange} />
            </div>
            <div className="col-5 text-left-important my-auto">

              <h5 className="h5 text-white letra-standar">Punto de Venta</h5>
            </div>
          </div> */}
          {/* <div className="row  mt-2">
            <div className="col-7">
              {selectedOption && (


                <div style={{ marginTop: '5px', background: '#fff', borderRadius: '4px', padding: '6px', border: '1px solid #ced4da', display: 'flex', alignItems: 'center' }}>

                  <span style={{ color: '#777' }} className="letra-standar"> {selectedOption.repositorName}</span>
                </div>
              )}
              {!selectedOption && (


                <div style={{ marginTop: '0px', background: '#fff', borderRadius: '4px', padding: '6px', border: '1px solid #ced4da', display: 'flex', alignItems: 'center' }}>

                  <span style={{ color: '#777' }} className="letra-standar">No seleccionado</span>
                </div>
              )}
            </div>
            <div className="col-5 text-left-important my-auto">
              <h5 className="h5 text-white letra-standar">Repositor </h5>
            </div>
          </div> */}
          <div className="row mt-2">
            <div className="col-7">
              <div style={{ marginTop: '0px', background: '#fff', borderRadius: '4px', padding: '6px', border: '1px solid #ced4da', display: 'flex', alignItems: 'center' }}>

                <span style={{ color: '#777' }} className="letra-standar">Especias</span>
              </div>
            </div>
            <div className="col-5 text-left-important my-auto">
              <h5 className="h5 text-white letra-standar">Categoría</h5>
            </div>
          </div>
        </div>


        <div className="text-center  mt-5">

          <DragArea6 sucursal={sucursalSeleccionada && sucursalSeleccionada} supervisor={supervisorSeleccionado && supervisorSeleccionado} repositor={repositorSeleccionado} select={repositorSeleccionado && repositorSeleccionado} puntoVenta={puntoVentaSeleccionado && puntoVentaSeleccionado} direccion={direccionSeleccionada && direccionSeleccionada} datosIp={valoresIP}></DragArea6>
        </div>

        <div className="row mt-3">

          <div className="col-4 text-center my-auto">
            <img src={logo3} className="text-white" width={120} alt="logo" />
          </div>
          <div className="col-4 text-center my-auto">
            <img src={logo4} width={120} alt="logo" />
          </div>
          <div className="col-4 text-center my-auto">
            <img src={logo2} className="mt-2" width={120} alt="logo" />
          </div>
        </div>
        <br></br>
        <br></br>
      </header>
    </div>
  );
}


export default FormularioV2_2;
