import logo from "../logo-lavirginia.svg";
import logo2 from "./logo-fractal.png";
import logo3 from "./logo-loger.png";
import logo4 from "../cognitive.png";
import styled from "@emotion/styled";
import Select from "react-select";
import axios from "axios";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../App.css";
// import Dropdown from "./componentes/dropdown/dropdown.js";
import DragArea from "./dragArea/dragArea";
// import DragArea2 from "./componentes/dragArea/dragArea2";
import { useEffect, useState } from "react";
import DragArea3 from "./dragArea/dragArea3";
import { ENDPOINT_NODE } from "../backend";
import DragArea4 from "./dragArea/dragArea4";

const FormularioV3 = () => {
  const [busqueda, setBusqueda] = useState("");
  const [tabladFiltrado, setTablaFiltrado] = useState(["a", "b", "c"]);
  const [valores, setValores] = useState(["a", "b", "c"]);
  const [valoresaux, setValoresAux] = useState([1, 2, 3]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [valoresIP, setValoresIP] = useState(null);
  const [newInfo,setNewInfo]=useState(null);
  const [options,setOptions] =useState(null)
  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const axiosJWT = axios.create();
  const originalOptions = [
    {
      value: "0300004216",
      label: "DISCO (2) CAP/B.NORTE/TALCAHUA - Fernando Codemo",
    },
    {
      value: "0300004402",
      label: "COTO (163) CAP/B NORTE/JUNIN - Fernando Codemo",
    },
    {
      value: "0300004536",
      label: "COTO (80) CAP/RECOLETA/FRENCH - Fernando Codemo",
    },
    {
      value: "0300004806",
      label: "DISCO (35) CAP/B.NORTE/URIBURU - Fernando Codemo",
    },
    {
      value: "0300004819",
      label: "INC CAP/006/SALGUERO/SAL - Fernando Codemo",
    },
    { value: "0300006638", label: "COTO 203/CAP/BOTANICO - Fernando Codemo" },
    {
      value: "0300004560",
      label: "COTO (2) CAP/PATERNAL/S.MARTIN - Julian Cantilo",
    },
    {
      value: "0300008992",
      label: "CARREFOUR 198/NUÑEZ/AV.CABILDO - Julian Cantilo",
    },
    {
      value: "0300009866",
      label: "CARREFOUR 120/BELGRANO/CABILDO-MON - Julian Cantilo",
    },
    {
      value: "0300004489",
      label: "COTO (22) CAP/BELGRANO/ELCANO - Leandro Rojas",
    },
    {
      value: "0300004494",
      label: "COTO (170) CAP/NUÑEZ/LIBERTADOR - Leandro Rojas",
    },
    {
      value: "0300004541",
      label: "COTO (59) CAP/V.URQUIZA/MONROE - Leandro Rojas",
    },
    {
      value: "0300004552",
      label: "COTO (44) CAP/COUGHLAN/MONROE - Leandro Rojas",
    },
    {
      value: "0300009878",
      label: "CARREFOUR CAP/271/BELGRANO/MONROE - Leandro Rojas",
    },
    {
      value: "0300003430",
      label: "DISCO (23) CAP/PALERMO/BULNES - Macario Torrico",
    },
    {
      value: "0300004209",
      label: "VEA (10) CAP/ONCE/LARREA - Macario Torrico",
    },
    {
      value: "0300004523",
      label: "JUMBO CAP/PALERMO/CERVIÑO - Macario Torrico",
    },
    {
      value: "0300004543",
      label: "COTO (56) CAP/PALERMO/HONDURAS - Macario Torrico",
    },
    {
      value: "0300004804",
      label: "DISCO (39) CAP/PALERMO/PEÑA - Macario Torrico",
    },
    {
      value: "0300009862",
      label: "CARREFOUR CAP/148/B.NORTE/SANTA FE - Macario Torrico",
    },
    {
      value: "0300009874",
      label: "CARREFOUR CAP/215/PALERMO/R.E.ORTIZ - Macario Torrico",
    },
    {
      value: "0300004204",
      label: "DISCO (18) CAP/RECOLETA/QUINTA - Marta Dandl",
    },
    {
      value: "0300004405",
      label: "DISCO (46) CAP/RETIRO/ESMERALD - Marta Dandl",
    },
    {
      value: "0300004496",
      label: "COTO (160) CAP/RETIRO/ANTARTID - Marta Dandl",
    },
    {
      value: "0300004542",
      label: "COTO (58) CAP/B.NORTE/VIAMONTE - Marta Dandl",
    },
    {
      value: "0300009864",
      label: "CARREFOUR CAP/118/B.NORTE/VTE.LOPEZ - Marta Dandl",
    },
    {
      value: "0300009868",
      label: "CARREFOUR CAP/148/CENTRO/RIVADAVIA - Marta Dandl",
    },
    {
      value: "0300004361",
      label: "PLAZAVEA (700) CAP/CHACARITA/C - Natalia Albornoz",
    },
    {
      value: "0300004525",
      label: "CARREFOUR CAP/206/BARRIO NORTE - Natalia Albornoz",
    },
    {
      value: "0300004539",
      label: "COTO (66) CAP/BELGRANO/MAURE - Natalia Albornoz",
    },
    {
      value: "0300004549",
      label: "COTO (47) CAP/BELGRANO/AMENABA - Natalia Albornoz",
    },
    {
      value: "0300004551",
      label: "COTO (45) CAP/BELGRANO/CABILDO - Natalia Albornoz",
    },
    {
      value: "0300004554",
      label: "COTO (42) CAP/B.NORTE/PARAGUAY - Natalia Albornoz",
    },
    {
      value: "0300004558",
      label: "COTO (24) CAP/PALERMO/S.ORTIZ - Natalia Albornoz",
    },
    {
      value: "0300004568",
      label: "COTO (158) GBA/PALERMO/CHARCAS - Natalia Albornoz",
    },
    {
      value: "0300004571",
      label: "COTO (176)GBA/BELGRANO/T.GARCI - Natalia Albornoz",
    },
    {
      value: "0300004579",
      label: "DISCO 977/CAP/BELGRANO/CUBA - Natalia Albornoz",
    },
    {
      value: "0300009871",
      label: "CARREFOUR CAP/150/PALERMO/STA FE - Natalia Albornoz",
    },
    { value: "0300004417", label: "COTO (61) CAP/ONCE/ALSINA - Olga Gomez" },
    {
      value: "0300004497",
      label: "COTO (159) CAP/CONGRESO/MITRE - Olga Gomez",
    },
    {
      value: "0300004537",
      label: "COTO (78) CAP/BALBANERA/MEXICO - Olga Gomez",
    },
    { value: "0300004550", label: "COTO (46) CAP/ONCE/RIVADAVIA - Olga Gomez" },
    {
      value: "0300009846",
      label: "CARREFOUR CAP/151/ONCE/RIVADAVIA - Olga Gomez",
    },
    {
      value: "0300004401",
      label: "COTO (91) CAP/ABASTO/AGUERO - Patricia Gioffre",
    },
    {
      value: "0300004418",
      label: "DISCO (247) CAP/CABALLITO/MORE - Patricia Gioffre",
    },
    { value: "0300004479", label: "JUMBO/CAP/AV.ACOYTE - Patricia Gioffre" },
    {
      value: "0300004527",
      label: "COTO (136) CAP/CABALLITO/GOYEN - Patricia Gioffre",
    },
    {
      value: "0300004534",
      label: "COTO (84) CAP/ALMAGRO/C.BARROS - Patricia Gioffre",
    },
    {
      value: "0300004535",
      label: "COTO (82) CAP/V.CRESPO/MALABIA - Patricia Gioffre",
    },
    {
      value: "0300004818",
      label: "DISCO (30) CAP/CABALLITO/RIVAD - Patricia Gioffre",
    },
    {
      value: "0200038541",
      label: "DORINKA (2997) CAP/CONSTITUYEN - Sebastian Sanchez",
    },
    {
      value: "0300003466",
      label: "INC GBA/015/SAN MARTIN/A - Sebastian Sanchez",
    },
    {
      value: "0300004363",
      label: "JUMBO 5259 CAP/V.HORTUSA/T - Sebastian Sanchez",
    },
    {
      value: "0300004813",
      label: "COTO (181)CAP/SAAVEDRA/R BALBI - Sebastian Sanchez",
    },
    {
      value: "0300009875",
      label: "CARREFOUR CAP/220/V.URQUIZA/ROOS - Sebastian Sanchez",
    },
    {
      value: "0300009877",
      label: "CARREFOUR CAP/258/V.PUEYRREDON - Sebastian Sanchez",
    },
    {
      value: "0300004411",
      label: "VEA (119) CAP/V.CRESPO/ARAOZ - Otero Alderete",
    },
    {
      value: "0300004492",
      label: "CARREFOUR GBA/219/CABALLITO/AV - Otero Alderete",
    },
    { value: "0300004524", label: "JUMBO CAP/ALMAGRO/YATAY - Otero Alderete" },
    {
      value: "0300004531",
      label: "COTO (103) CAP/CABALLITO/GAONA - Otero Alderete",
    },
    {
      value: "0300004555",
      label: "COTO (41) CAP/ALMAGRO/D.VELEZ - Otero Alderete",
    },
    {
      value: "0300004581",
      label: "COTO #168CAP/V CRESPO/JB JUSTO - Otero Alderete",
    },
    {
      value: "0300004681",
      label: "JUMBO 5263(CAP)JB JUSTO 4701  - Otero Alderete",
    },
  ];
  // const options = newInfo.map((option) => ({
  //   value: option.codigo,
  //   label: option.punto_venta, // Mostrar solo la parte antes del guión
  //   repositorName: option.repositor,
  //   categoria:option.categoria,
  //   status: option.status,
  //   cantImages: option.cant_images // Guardar el nombre del repositor
  // }));
  const filtrarArray = (array) => {
    var ayuda2 = [];
    var items2 = array;
    for (let i = 0; i < items2.length; i++) {
      ayuda2.push(i);
    }
    setValores(items2);
    setValoresAux(ayuda2);

    //   setCurrentItems(ayuda2.slice(itemOffset, itemOffset + itemsPerPage));
    //   setPageCount(Math.ceil(items2.length / itemsPerPage));
  };
  const handleChange = (e) => {
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  };

  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = tabladFiltrado.filter((elemento) => {
      if (
        (elemento.image != null &&
          elemento.image.includes(terminoBusqueda.toLowerCase())) ||
        (elemento.job_id != null &&
          elemento.job_id.includes(terminoBusqueda.toLowerCase())) ||
        (elemento.created_at != null &&
          elemento.created_at
            .toString()
            .includes(terminoBusqueda.toLowerCase()))
      ) {
        return elemento;
      }
    });

    filtrarArray(resultadosBusqueda);
  };
  const getIp = async () => {
    try {
      // const response = await axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=bfa2898e16254f0baa4d590f25a4268f');
      // console.log(response);
      const response = await fetch(
        "https://ipgeolocation.abstractapi.com/v1/?api_key=bfa2898e16254f0baa4d590f25a4268f"
      );
      const data = await response.json();

      setValoresIP({
        ip_user: data.ip_address,
        user_country: data.city + ", " + data.country,
        conection_type: data.connection.connection_type,
        latitude: data.latitude,
        longitude: data.longitude,
        current_time: data.timezone.current_time,
      });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getIp();
    getInfoTable();
  }, []);

  const getInfoTable = async () => {
    const response = await axiosJWT.get(ENDPOINT_NODE + "/muebles", {
      
    });
    setNewInfo(response.data)
    setOptions(response.data.map((option) => ({
      value: option.codigo,
      label: option.punto_venta, // Mostrar solo la parte antes del guión
      repositorName: option.repositor,
      categoria:option.categoria,
      status: option.status,
      cantImages: option.cant_images // Guardar el nombre del repositor
    })))
    console.log(options)
  };
  
  return (
    <div className="App container fondo">
      <br></br>
      <div className="row ">
        <div className="col-4 text-center"></div>
        <div className="col-4 text-center"></div>
        <div className="col-4 text-center">
          <a href="/upload">Subir archivo</a>
        </div>
      </div>
      {/* <Formulario></Formulario> */}
      {/* <DragArea2 handleImageUpload={handleImageUpload} /> */}
      <header className="text-center ">
        <div className="container">
          <img src={logo} className="App-logo img-fluid mt-5" alt="logo" />
        </div>

        {/* <Dropdown dropdownTitle="primer iterado" items={items} /> */}

        <br></br>
        <br></br>
        <div className="container">
          <div className="row mt-5 ">
            <div className="col-7 text-center">
              <Select
                options={options}
                isSearchable
                onChange={handleOptionChange}
              />
            </div>
            <div className="col-5 text-left-important my-auto">
              {/* <Label>Punto de venta</Label> */}
              <h5 className="h5 text-white letra-standar">Punto de Venta</h5>
            </div>
          </div>
          {selectedOption && selectedOption.status==0 && (
            <div className="row mt-2">
            <div className="col-7">
              <div
                style={{
                  marginTop: "0px",
                  background: "#fff",
                  borderRadius: "4px",
                  padding: "6px",
                  border: "1px solid #ced4da",
                  display: "flex",
                  alignItems: "center",
                  color: 'red',
                }}
              >
               Punto de venta no disponible
              </div>
            </div>
            <div className="col-5 text-left-important my-auto">
            </div>
          </div>
          )}
          <div className="row  mt-2">
            <div className="col-7">
              {selectedOption && (
                <div
                  style={{
                    marginTop: "5px",
                    background: "#fff",
                    borderRadius: "4px",
                    padding: "6px",
                    border: "1px solid #ced4da",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ color: "#777" }} className="letra-standar">
                    {" "}
                    {selectedOption.repositorName}
                  </span>
                </div>
              )}
              {!selectedOption && (
                <div
                  style={{
                    marginTop: "0px",
                    background: "#fff",
                    borderRadius: "4px",
                    padding: "6px",
                    border: "1px solid #ced4da",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ color: "#777" }} className="letra-standar">
                    No seleccionado
                  </span>
                </div>
              )}
            </div>
            <div className="col-5 text-left-important my-auto">
              <h5 className="h5 text-white letra-standar">Repositor </h5>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-7">
              <div
                style={{
                  marginTop: "0px",
                  background: "#fff",
                  borderRadius: "4px",
                  padding: "6px",
                  border: "1px solid #ced4da",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "#777" }} className="letra-standar">
                  {selectedOption && selectedOption.categoria}
                </span>
              </div>
            </div>
            <div className="col-5 text-left-important my-auto">
              <h5 className="h5 text-white letra-standar">Categoría</h5>
            </div>
          </div>
          
        </div>

        <div className="text-center  mt-5">
          <DragArea4
            select={selectedOption && selectedOption.repositorName}
            puntoVenta={selectedOption && selectedOption.value}
            categoria={selectedOption && selectedOption.categoria}
            datosIp={valoresIP}
            cantImagen={selectedOption && selectedOption.cantImages}
            status={selectedOption && selectedOption.status}
          ></DragArea4>
        </div>

        <div className="row mt-3">
          <div className="col-4 text-center my-auto">
            <img src={logo3} className="text-white" width={120} alt="logo" />
          </div>
          <div className="col-4 text-center my-auto">
            <img src={logo4} width={120} alt="logo" />
          </div>
          <div className="col-4 text-center my-auto">
            <img src={logo2} className="mt-2" width={120} alt="logo" />
          </div>
        </div>
        <br></br>
        <br></br>

      </header>
    </div>
  );
};

export default FormularioV3;
