import { useState , useEffect} from "react";
import axios from "axios";
import { ENDPOINT_CLASIFIYER } from "../../backend";
import Modal from 'react-modal';
// import uploadService from "../../services/upload.service";
import {Toaster, toast } from "react-hot-toast";
// import Alert from '../../components/error/ErrorAlert';
// import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
Modal.setAppElement('#root');

function DragArea6({ sucursal, supervisor, repositor, select,puntoVenta, direccion,datosIp}){
    const [ImageSelectedPrevious, setImageSelectedPrevious] = useState([]);
    const [archivos,setArchivos] = useState("");
    const [myvalue,setmyValue] = useState('')
    const [prueba,setPrueba] = useState([])
    const [base64, setBase64] = useState([])
    const [activate,setActivate] = useState(false)
    const [progress,setProgress] = useState(0)
    const [token,setToken ] = useState('')  
    const [finish,setFinish] = useState(false)
    const total = 10;
    const [texto,setTexto] = useState("Enviar")
    const [showAlert, setShowAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [showModal, setShowModal] = useState(false);
    const changeImage = (e) => {   
      añadirImagen(e.target.files)
      tenerToken()
      for (let i = 0; i<e.target.files.length; i++){     
        if (e.target.files[i] !== undefined) {
          const reader = new FileReader();        
          reader.readAsDataURL(e.target.files[i]);
          reader.onload = (e) => {
            e.preventDefault();
            setBase64(base64=>base64.concat(e.target.result))
            
          };
        }
      }
    };
    useEffect(() => {
      // getToken(107,1);
  }, []);
    const UploadFiles = async ()=>{
      setTexto("Enviando...")
      try {
        const EndPoint = ENDPOINT_CLASIFIYER+"/api-send-images"
        let formData = new FormData();
            for (let i= 0 ; i<prueba.length; i++){
              formData.append("image", prueba[i]);
            }
            
            formData.append("username", "lavirginia@cognitive.la")
            // formData.append("punto_venta", puntoVenta)
            // formData.append("repositor", select)
            formData.append("punto_venta", puntoVenta.value)
            formData.append("repositor", repositor.value)
            formData.append("sucursal", sucursal.value)
            formData.append("supervisor", supervisor.value)
            formData.append("direccion", direccion.value)
            formData.append("categoria", "Especias")
            formData.append("ip_user", datosIp.ip_user)
            formData.append("user_country", datosIp.user_country)
            formData.append("conection_type", datosIp.conection_type)
            formData.append("latitude", datosIp.latitude)
            formData.append("longitude", datosIp.longitude)
            formData.append("current_time", datosIp.current_time)
            await axios({
              url: EndPoint,
              method: "POST",
              headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + token,
              },
              // Attaching the form data
              data: formData,
            })
            .then((response) => {
              // toast.success('Your image has been processed')
              setShowModal(true);
              setPrueba([])
              setBase64([])
              // sumarCantidad(this.id_usuario,this.servicio);
              
              // this.guardarNombreImage( this.id_usuario ,this.servicio,currentFile.name,response.data.Jobid)
            })
          } catch (error) {
            setShowAlert(true);
           
            if (error.response) {
              console.log(error.response.data); // Información detallada sobre el error de respuesta
              console.log(error.response.status); // Código de estado HTTP de la respuesta
              setMessageAlert("Error al mostrar");
            } else if (error.request) {
              console.log(error.request); // Información sobre la solicitud sin respuesta
              setMessageAlert("Error de solicitud");
            } else {
              console.log(error.message); // Otros errores
              setMessageAlert("Error desconocido");
            }
          }
    }
  //   const getToken= async(id_user,service)=>{
  //     try {
  //       const response = await axios.post('ENDPOINT_NODE+/get-password-token', {
  //           id_user: id_user,
  //           id_service: service
  //       });
  //   } catch (error) {
        
  //       if (error.response) {
  //         // setMsg(error.response.data.msg);
  //       }
  //   }
  // }
    
    const tenerToken = () => {
      let formData = new FormData();
      formData.append('username', "cristian@santabros.com.ar");
      formData.append('password', "carlos");
      axios({
          // Endpoint to send files
          url: ENDPOINT_CLASIFIYER+"/get-token",
          method: "POST",
          // Attaching the form data
          data: formData,
        })
        .then((response) => {
          setToken(response.data.access_token);
        });
      }
    const añadirImagen = (value) => {
      Array.from(value).forEach(archivos=>{
          setPrueba(prueba=>prueba.concat(archivos))
      })
    }
    const removerImage = (value) =>{
      
      const a = base64
      const b = a.splice(value,1)
      setBase64(a)
      setPrueba(prueba.filter((item) => item !== prueba[value]))
    }
  
    const cerrarModal = () => {
      setShowModal(false)
      window.location.reload()
    }
    return (
      <div className="container">
        {/* <StyleDragArea> */}
        {/* {showAlert && <Alert message={messageAlert} />} */}
         
          <div className="image-upload-wrap text-center">
          <input
              className="file-upload-input"
              type="file"
              accept=".png, .jpg, .jpeg"
              multiple
              value={myvalue}
              onChange={(e) => {
                changeImage(e);
              }}
            />
          <div className="text-information mt-4 ">
              <h6 className="h6 fontSize">Seleccione de la galeria de fotos todas las imágenes del punto de venta aquí</h6>
              <div className="text-center">
              <FontAwesomeIcon className="text-warning" icon={faCloudUploadAlt} />
  
              </div>
              
            <div className="">
            <h6 className="h6 " >Número de imágenes: {prueba.length}</h6>
            </div>
              
              
            </div>
            
            
          </div>
          {/* Modal */}
        <Modal
          isOpen={showModal}
          onRequestClose={() => cerrarModal()}
          contentLabel="Imagen Enviada"
          className="modal-custom text-center"    >
          <h2>¡Imagen Enviada!</h2>
          <p>Tu imagen ha sido enviada exitosamente.</p>
          <div class="cerrar-btn-container">
          <button className="btn btn-warning cerrar-btn my-auto text-white" onClick={() => cerrarModal()}>Cerrar</button>
          </div>
        </Modal>
          <div className="row mt-5">
          {base64.map((imagen, index) => (
              <div key={index} className="text-center col-6 ">
              <img 
                src={imagen}
                alt=""
                height="75px"
                width="112px"
                className="imagenredonda"
              />
              <div>
              <button onClick={(e)=>removerImage(index,e)} className="my-3 btn btn-danger custom-btn">Remover</button>
              </div>
            </div>
                      ))}
  
          </div>
  
        <div className="row">
              {/* <div className="col-6 text-center mt-5">
                <a href="/services">
                  <button className="btn btn-primary btn-lg ml-2 mb-4">Return</button>
                </a>
              </div> */}
              
              <div className="col-12 text-center">
                <div className="mb-5" >
                <div className="text-center">
                {(prueba.length > total) ? <button
                    className="btn btn-warning text-white custom-btn"
                    disabled={prueba!=''}
                  >
                  
                   Maximo {total} imágenes
                  </button>:
                  <button
                    className="btn btn-warning text-white custom-btn "
                    disabled={prueba=='' || select==null}
                    onClick={UploadFiles}
                  >
                  
                    {texto}
                  </button> }
                
                </div>
  
                </div>
              </div>
            </div>
            <Toaster
              position="bottom-center"
              reverseOrder={false}
            />
      </div>
      
    );
  }

export default DragArea6;
