
import styled from '@emotion/styled';
import Select from 'react-select';
import axios from "axios";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
// import Dropdown from "./componentes/dropdown/dropdown.js";
// import DragArea2 from "./componentes/dragArea/dragArea2";
import { useEffect,useState } from "react";
import FormularioV1 from "./componentes/formularioV1";
import FormularioV2 from './componentes/formularioV2';
import FormularioV2_2 from './componentes/formularioV2_2';
import Upload from './componentes/upload/upload';
import FormularioV3 from './componentes/formularioV3';
import FormularioV4 from './componentes/formularioV4';
function App() {


   
  return (
    <BrowserRouter>
     <Switch>
      <Route exact path="/version-last">
        <FormularioV2></FormularioV2>
      </Route>
      <Route exact path="/">
        <FormularioV2_2></FormularioV2_2>
      </Route>
      <Route exact path="/formularioV1-none">
        <FormularioV1></FormularioV1>
      </Route>
      <Route exact path="/formularioV3">
        <FormularioV3></FormularioV3>
      </Route>
      <Route exact path="/formularioV4">
        <FormularioV4></FormularioV4>
      </Route>
      <Route exact path="/upload">
        <Upload></Upload>
      </Route>
     </Switch>
  
</BrowserRouter>
  );
}

export default App;
